@import "../inc/base";

img {
    &.blason {
        aspect-ratio: 1;
        display: inline-block;
        object-fit: contain;
        vertical-align: middle;

        width: 3.5em;
        
        &.blason-petit {
            width: 1.5em;
        }
        
        
    }

    &.alignright {
        float: right;
        margin-left: .5em;
        margin-bottom: .5em;

    }

    &.aligncenter {
        margin: .5em auto;
        display: block !important;
    }

    &.alignleft {
        float: left;
        margin-right: .5em;
        margin-bottom: .5em;
    }
}

h2 {
    .blason:not(.blason-petit) {
        width: 1.2em;

        @include moyen {

            width: 2.5em;
        }
    }
}