@import "../inc/base";

.titre-page {
    flex:1;
    margin-right:var(--marge);
    >h2 {
        margin: 0;
        >img {
            width: 32px;
            height: 32px;
            object-fit: contain;
            vertical-align: text-bottom;
        }
    }
}
[data-zone="liste"] {
    [data-zone="element-de-liste"] {
        margin-top: var(--marge-grande);
        @include grille(7,9);
        >* {
            &:first-child {
                color:inherit;
                @include grilleSpan(2);
                text-decoration: none;
                display: block;
                text-align: center;
                font-family: var(--police-sans-serif);
                font-weight: normal;
                font-variation-settings: "wght"900;
                font-size: 12vw;
                line-height: 1em;
                text-align: center;

            }
            &:last-child {
                @include grilleSpan(4,6);
                >h2 {
                    margin: 0;
                }
                >span {
                    font-style: italic;
                    font-weight: normal;
                    font-variation-settings: "wght"600;
                }
                >div {
                    margin-top: var(--marge);
                }
            }
        }
    }
}