@import "../inc/base";

html {
    scroll-padding-top: calc(200px + var(--marge));
}

[data-zone="topv2"] {
    display: flex;
    flex-direction: column;
    @include gap();

    [data-zone="numeros"] {
        height: 100px;
        overflow: hidden;

        [data-zone="scroll"] {
            scroll-snap-type: x mandatory;
            scroll-snap-align: center;
            scroll-margin-left: var(--marge);
            overflow-x: hidden;
            overflow-x: auto;
            display: flex;
            padding-bottom: 100px;
            @include gap();

            [data-zone="numero"] {

                &:hover,
                &:active,
                &:focus,
                &[data-selected="true"] {
                    img {
                        opacity: .5;
                    }
                }
            }

            >a {
                flex: 0 0 calc(var(--largeur-colonne) * 2 + var(--marge));

                @include moyen {
                    flex: 0 0 var(--largeur-colonne);
                }

                height: 100px;
                display: flex;
                flex-direction: column;
                background-color: var(--color-brand);

                span {
                    color: var(--color-brand-alt);
                    font-family: var(--police-sans-serif);
                    font-weight: normal;
                    font-weight: var(--font-weight-bold);
                    @include paddingInline(var(--marge-petite));
                    text-align: center;
                }

                img {
                    opacity: 1;
                    transition: opacity .5s ease;
                    ;
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    flex: 1;
                }
            }
        }
    }

    [data-zone="slider"] {
        @include grid(('mini':'content', 'petit':'content', 'moyen':7, 'grand':9));
        >* {
            @include span(('all':'content'));
        }

        [data-zone="slides"] {

            [data-zone="slide"] {
                >div>div {
                    @include petit {
                        max-width: calc(100% - (2 * (var(--largeur-colonne) + var(--marge))));
                    }
                }
            }
        }
    }
}