@import "../inc/base";


.page-erreur {
    >h1,p{
        text-align: center;
    }
    >div {
        @include grid();
        
        >figure {
            // border : 1px solid;

            @include span((
                'grand':('span':5,'start':3),
                'all':('span':3,'start':3),
                'mini':4
            ));

            img {
                width: 100%;
            }
        }
    }

}

// .content{
//     width : 60%;
//     background-color: red;
//     margin : auto;
// }