@import "../inc/base";



a.lien-connexe,
div.lien-connexe {
	display: flex;
	position: relative;
	font-family: var(--police-sans-serif);

	@include gap(var((--marge-micro)));
	// margin-bottom: var(--marge);

	&:before {
		content: '';
		background: var(--color-brand-alt);
		display: inline-block;
		margin: var(--marge-tres-micro);
		mask: url(/img/svg/chevrons.svg) no-repeat;
		mask-position: center;
		mask-size: contain;
		flex: 0 0 15px;
	}

	font-weight: normal;
	font-weight: var(--font-weight-bold);

	>span,
	>h3,
	>a {
		margin:0;
		padding:0;
		font-size: var(--taille-texte-moyen-grand);
		color: inherit;

		&:before {
			font-weight: var(--font-weight-medium);
			font-weight: var(--font-weight-medium);
			display: block;
			margin-right: var(--marge-mini);
			font-family: var(--police-serif);
		}

		&[data-titre]:before {
			content: attr(data-titre);
		}

		&:not([data-titre]),
		&[data-titre=""] {
			&:before {
				content: 'À lire aussi ';
			}
		}
	}

	@include moyen {

		&:before {
			content: "";
			background: var(--color-brand);
			display: block;
			position: absolute;
			top: 0;
			width: var(--largeur-colonne);
			left: calc(var(--largeur-colonne) * -1);
			height: 100%;
			mask: none;
			flex: none;
			margin: 0;
		}

		>span,
		>h3,
		>a {
			&:before {
				display: inline-block;
			}

			&[data-titre]:before {
				content: attr(data-titre) ' : ';
			}

			&:not([data-titre]),
			&[data-titre=""] {
				&:before {
					content: 'À lire aussi : ';
				}
			}
		}
	}

	box-sizing: border-box;
	padding: var(--marge-mini);
	background: var(--color-brand);
	color: var(--color-brand-alt);
	display: flex;
}

// div.lien-connexe a::before {
// 	position:static;
// 	display: inline-block;
// 	content: attr(title) ':' ;
// 	color: inherit;
// 	text-decoration: none;
// 	width: initial;
// 	font-weight: var(--font-weight-medium);
// 	font-weight: var(--font-weight-medium);
// }