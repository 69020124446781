@import "../inc/base";



.type-standfirst,
.chapo {
	font-weight: var(--font-weight-bold);
}


.type-standfirst {
	font-family: var(--police-sans-serif);
	font-size: var(--taille-texte-grand);
}

.chapo {
	font-family: var(--police-sans-serif);
	// font-size: var(--font-size-fluid-1);
	line-height: .95em;
	letter-spacing: -.55px;
	font-size: var(--taille-texte-grand);
	line-height: 1.15em;

}

.type-secondary {
	font-size: var(--taille-texte-petit);
}

.type-tertiary {
	font-size: var(--taille-texte-tout-petit);
}

.type-mini {
	font-size: var(--taille-texte-tout-petit);
	line-height: calc(var(--lineheight-text) / 1.05);
}

