@use 'sass:math';

@mixin paddingBlock($valeur) {
	padding-top: $valeur;
	padding-bottom: $valeur;
}

@mixin marginBlock($valeur) {
	margin-top: $valeur;
	margin-bottom: $valeur;
}

@mixin paddingInline($valeur) {
	padding-left: $valeur;
	padding-right: $valeur;
}

@mixin marginInline($valeur) {
	margin-left: $valeur;
	margin-right: $valeur;
}

@function isMap($var) {
	@return type-of($var)=='map';
}

@function notEmpty($value) {
	@return empty($value)==false;
}

@function empty($value) {
	@if not $value or $value=="" or $value==0 or $value==() or length($value)==0 {
		@return true;
	}

	@return false;
}


/**
*/

@function param($params, $key, $default: false) {
	@if isMap($params) {
		@if map-has-key($params, $key) {
			$value: map-get($params, $key);

			@if notEmpty($value) or $value==0 {
				@return $value;
			}

			@else {
				@return $default;
			}
		}

		@else {
			@return $default;
		}
	}

	@else {
		@return $default;
	}
}


/**
* Définir la largeur d'un element dans une grille (petit ou grande)
*/

@mixin grille($petit: '', $grand: '', $gap: false, $rowGap: false) {
	// @warn "This old mixin is deprecated!";
	display: grid;

	@if $grand=='' and $petit=='' {
		$grand: 9;
		$petit: 7;
	}

	@if $grand=='' {
		$grand: $petit;
	}

	@include gap($gap, $rowGap);

	@include petite-grille {
		grid-template-columns: repeat($petit, [col] 1fr);
	}

	@include grande-grille {
		grid-template-columns: repeat($grand, [col] 1fr);
	}
}

@mixin grilleSpan($span1: false, $span2: false, $start1: false, $start2: '') {

	// @warn "This old mixin is deprecated!";
	@if $span1==false and $span2==false {
		$span1: 7;
		$span2: 9;
	}

	@if $start2=='' {
		$start2: $start1;
	}

	@if $span2 {
		@include petite-grille {
			@if $start1 {
				grid-column: $start1 / span $span1;
			}

			@else {
				grid-column: span $span1;
			}
		}

		@include grande-grille {
			@if $start2 {
				grid-column: $start2 / span $span2;
			}

			@else {
				grid-column: span $span2;
			}
		}
	}

	@else {
		@if $start1 {
			grid-column: $start1 / span $span1;
		}

		@else {
			grid-column: span $span1;
		}
	}
}

@mixin grande-grille {
	// @warn "This old mixin is deprecated!";
	$this: &;

	@at-root {
		html:not([data-habillage="true"]):not([data-arche="true"]) body:not(.body-habillage) {
			@include tres-grand {
				#{$this} {
					@content;
				}
			}
		}
	}
}

@mixin petite-grille {
	// @warn "This old mixin is deprecated!";
	$this: &;

	@include petit {
		@include moins-que-tres-grand {
			@content;
		}
	}

	@at-root {

		html[data-habillage="true"],
		body.body-habillage,
		html[data-arche="true"] {
			#{$this} {
				@content;
			}
		}
	}
}

@mixin mini-grille {

	// @warn "This old mixin is deprecated!";
	@include moins-que-petit {
		@content;
	}
}


/**
 * Gere la propriété gap quand on est sur un navigateur qui ne le supporte pas
 **/
// @mixin gap($gap:false, $rowGap:false) {
// 	$this: &;

// 	@if $gap==false or $gap==null or $gap=='' {
// 		$gap: var(--marge);
// 	}

// 	gap: $gap;

// 	@if $rowGap !=false {
// 		row-gap: $rowGap;
// 	}
// }

@mixin columnGap($gap: false) {
	$this: &;

	@if $gap==false or $gap==null or $gap=='' {
		$gap: var(--marge);
	}

	@at-root {
		#{$this} {
			flex-direction: column;
		}

		// html[data-browser-old]:not([data-browser-old="true"]) {
		html:not([data-browser-old="true"]) {
			#{$this} {
				gap: $gap;
			}
		}

		html {

			// &[data-browser-old="true"], &:not([data-browser-old]) {
			&[data-browser-old="true"] {
				#{$this} {
					content: 'column-gap';

					>* {
						content: 'column-gap-margin-bottom';
						margin-bottom: $gap;
					}

					>*:last-child {
						content: 'column-gap-last';
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@mixin gridGap($gap: false, $rowGap: false) {
	@if $gap==false or $gap==null or $gap=='' {
		$gap: var(--marge);
	}

	grid-gap: $gap;
	gap: $gap;

	@if $rowGap !=false {
		row-gap: $rowGap;
	}
}

@mixin gap($gap: false, $rowGap: false) {
	$this: &;

	@if $gap==false or $gap==null or $gap=='' {
		$gap: var(--marge);
	}

	@at-root {
		html:not([data-browser-old="true"]) {
			#{$this} {
				gap: $gap;

				@if $rowGap !=false {
					row-gap: $rowGap;
				}
			}
		}

		html {

			&[data-browser-old="true"] {
				#{$this}>*:not(:last-child) {
					margin-right: $gap;

					@if $rowGap !=false {
						margin-top: $rowGap;
					}
				}
			}
		}
	}
}


// Small devices
@mixin tout-petit {
	@media (min-width: #{$screen-tout-petit}) {
		@content;
	}
}

@mixin petit {
	@media (min-width: #{$screen-petit}) {
		@content;
	}
}

// Medium devices
@mixin moyen {
	@media (min-width: #{$screen-moyen}) {
		@content;
	}
}

// Large devices
@mixin grand {
	@media (min-width: #{$screen-grand}) {
		@content;
	}
}

// Extra large devices
@mixin tres-grand {
	@media (min-width: #{$screen-tres-grand}) {
		@content;
	}
}

// Extra large devices
@mixin geant {
	@media (min-width: #{$screen-geant}) {
		@content;
	}
}

// Small devices
@mixin moins-que-tout-petit {
	@media (max-width: #{$screen-tout-petit}) {
		@content;
	}
}

@mixin moins-que-petit {
	@media (max-width: #{$screen-petit}) {
		@content;
	}
}

// Medium devices
@mixin moins-que-moyen {
	@media (max-width: #{$screen-moyen}) {
		@content;
	}
}

// Large devices
@mixin moins-que-grand {
	@media (max-width: #{$screen-grand}) {
		@content;
	}
}

// Extra large devices
@mixin moins-que-tres-grand {
	@media (max-width: #{$screen-tres-grand}) {
		@content;
	}
}

@mixin entre-petit-et-moyen {
	@media (min-width: #{$screen-petit}) and (max-width: #{$screen-moyen}) {
		@content;
	}
}

@mixin entre-petit-et-grand {
	@media (min-width: #{$screen-petit}) and (max-width: #{$screen-grand}) {
		@content;
	}
}

@mixin entre-petit-et-tres-grand {
	@media (min-width: #{$screen-petit}) and (max-width: #{$screen-tres-grand}) {
		@content;
	}
}

@mixin entre-moyen-et-grand {
	@media (min-width: #{$screen-moyen}) and (max-width: #{$screen-grand}) {
		@content;
	}
}

@mixin entre-moyen-et-tres-grand {
	@media (min-width: #{$screen-moyen}) and (max-width: #{$screen-tres-grand}) {
		@content;
	}
}

@mixin entre-grand-et-tres-grand {
	@media (min-width: #{$screen-grand}) and (max-width: #{$screen-tres-grand}) {
		@content;
	}
}

/**

Permet d'intégrer un contour de 5px (pour l'instant statique) autour d'un texte 
La variable est la couleur du contour que l'on veut

**/
@mixin contourTexte($color, $width: 2) {

	$h: #{ceil(math.div($width,2))}px;
	$mh: #{ceil(math.div($width,-2))}px;
	text-shadow: $mh $mh 0 $color, $h $mh 0 $color, $mh $h 0 $color, $h $h 0 $color;

	/*	text-shadow: $color 4px 0px 0px, $color 3.87565px 0.989616px 0px, $color 3.51033px 1.9177px 0px, $color 2.92676px 2.72656px 0px, $color 2.16121px 3.36588px 0px, $color 1.26129px 3.79594px 0px, $color 0.282949px 3.98998px 0px, $color -0.712984px 3.93594px 0px, $color -1.66459px 3.63719px 0px, $color -2.51269px 3.11229px 0px, $color -3.20457px 2.39389px 0px, $color -3.69721px 1.52664px 0px, $color -3.95997px 0.56448px 0px, $color -3.97652px -0.432781px 0px, $color -3.74583px -1.40313px 0px, $color -3.28224px -2.28625px 0px, $color -2.61457px -3.02721px 0px, $color -1.78435px -3.57996px 0px, $color -0.843183px -3.91012px 0px, $color 0.150409px -3.99717px 0px, $color 1.13465px -3.8357px 0px, $color 2.04834px -3.43574px 0px, $color 2.83468px -2.82216px 0px, $color 3.44477px -2.03312px 0px, $color 3.84068px -1.11766px 0px, $color 3.9978px -0.132717px 0px;*/


}


@mixin stroke($width, $color) {
	$width: $width + 0;
	$shadow: 0 0 0 transparent;
	$i: 0;
	$w: 1;

	@while ($i < $width) {
		$i: $i + 1;
		$j: 0;
		$w: $w + 2;

		@for $r from 1 through $w {
			@for $c from 1 through $w {
				$x: $c - math.ceil(math.div($w, 2));
				$y: $r - math.ceil(math.div($w, 2));

				$shadow: #{$shadow},
					#{$x}px #{$y}px 0 $color;
			}
		}
	}

	text-shadow: $shadow;
}

@mixin titreArticle() {

	>h2,
	>h1 {
		// font-size: var(--taille-texte-tres-grand-smart);
		font-size: var(--taille-texte-grand-smart);
		color: var(--color-brand);
		line-height: var(--hauteur-ligne-petite);
		letter-spacing: -0.87px;
		// max-width: calc(100% - (var(--largeur-colonne) +  var(--marge)));
	}
}

@mixin headerArticle() {
	>header {
		display: flex;
		@include columnGap(var(--marge-petite));

		>div:not([class]) {
			display: flex;
			gap: var(--marge-mini);

			flex-direction: column;
			font-size: var(--taille-texte-moyen-grand);
			letter-spacing: -0.27px;

			@include moyen {
				@include gap();
				flex-direction: row;
			}

			strong {
				font-family: var(--police-sans-serif);
			}

			>div {
				display: flex;
				gap: var(--marge-mini);

				flex-direction: column;

				@include moyen {
					flex-direction: row;
					gap: var(--marge);
				}

			}

			time:first-of-type {
				text-transform: uppercase;
				font-weight: var(--font-weight-medium);
			}

			time.edit {
				display: none;
			}

			&:not(:has(.edit)) {
				time:last-of-type {
					display: none;
				}
			}

			.duree {
				justify-content: flex-start;

				@include moyen {
					justify-content: flex-end;
					border-left: 1px solid currentColor;
					padding-left: var(--marge);
				}

			}
		}

		@include titreArticle();
	}
}