.consignes {
	background-color: var(--color-brand);
	color: var(--color-brand-alt);
	padding: var(--marge-petite);
	font-size: var(--taille-texte-petit);

	a {
		--couleur-lien-rgb: var(--color-light-rgb);
	}
}

[data-zone="note"] {
	transition: opacity .5s ease;
	position: relative;
	display: flex;
	flex-direction: column;
	@include gap();

	&:not([data-note]) {

		// [data-zone="ma-note"], [data-action="noter"] {
		[data-zone="ma-note"] {

			// height: 0;
			// visibility: hidden;
			var:empty:before {
				display: inline;
				content: '?';
			}

			button[data-action="noter"] {
				opacity: 0;
			}
		}
	}


	>[data-zone="notes-content"] {
		@include grid(('all':'content'));

		>figure {
			margin: 0;
			@include span(('mini':1, 'all':1, 'grand':('start':2, 'span':1)));
			aspect-ratio: 1;

			@include moyen {
				aspect-ratio: .8;
			}

			// height: 0;
			// padding-bottom: 150%;
			position: relative;
			// border-radius: 50%;
			overflow: hidden;

			img {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;

				&[src*="png"] {
					object-fit: contain;
				}

				position: absolute;
			}
		}

		>header {
			@include span(('mini':3, 'petit':4, 'moyen':5, 'grand':('start':3, 'span':5)));

			display: flex;
			@include gap();
			flex-direction: column;

			>h3 {
				margin: 0;
			}

			@include moins-que-petit {
				// >h3 {
				// 	display: none;
				// }

				order: 3;
			}
		}
	}

	>div[data-zone="notes-wrapper"] {
		@include grid(('all':'content'));

		display: flex;
		gap: var(--marge);
		flex-wrap: wrap;



		h3 {
			flex: 0 0 100%;
		}



		>div[data-zone="notation"] {
			@include span(('all' : 'content'));
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			@include gap();
			justify-content: center;

			@include moyen {
				flex-direction: column;
				flex-direction: row;
				justify-content: space-around;
			}

			>div {
				min-width: 40%;

				@include moyen {
					min-width: calc(((var(--largeur-colonne) * 2) + var(--marge)) * 1.1);
				}

				align-items: center;

				&:first-of-type {
					// @include span(('mini':2, 'petit':('start':2, 'span':2), 'moyen':('start':2, 'span':2), 'grand':('start':3, 'span':2)));

					span {
						transition: filter 1s ease;
					}

					>span {
						>span:not(:has(.blurred)) {
							cursor: default;

							&:before,
							&:after {
								display: none;
							}
						}
					}
				}

				@include moins-que-petit {
					&:last-of-type {
						// @include span(('mini':'content'));
					}
				}

				display: flex;
				flex-direction: column;
				// margin: var(--marge-mini) 0;

				&[data-zone="ma-note"] {

					button {
						display: inline;

						text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
					}


				}

				>span {
					font-family: var(--police-sans-serif);
					font-weight: normal;

					&:first-of-type {
						font-weight: var(--font-weight-medium);
					}

					&:last-of-type {
						font-size: var(--taille-texte-grand-smart);
						font-variation-settings: "wght" 900;
					}

				}

				>p {
					font-size: var(--taille-texte-petit);
					font-family: var(--police-sans-serif);
					margin: 0;
				}

			}
		}

		>div[data-zone="bouton-notation"] {
			width: 100%;
			@include grid(('all' : 'content'));

			>div {
				@include span(('mini':('start':2, 'span':2), 'all':('start':6, 'span':1), 'grand' : ('start':8, 'span' : 1)));
				display: flex;
				justify-content: center;

				@include moyen {
					justify-content: end;
				}

				button[data-action="noter"] {
					font-size: var(--taille-texte-tres-grand);

					@include moyen {
						font-size: var(--taille-texte-grand);
					}

					justify-content: flex-start;

					// width: calc((var(--largeur-colonne) * 2) + var(--marge));

					// @include petit {
					// 	width: var(--largeur-colonne);
					// }

					// @include moyen {
					// 	width: 100%;
					// }
				}
			}

		}

		@include moins-que-petit {
			order: 2;
		}

		@include petit {
			// transform: translateX(calc(-1*var(--marge)));
			// width: calc(100% + var(--marge));

			h3 {
				display: none;
			}
		}

	}



	&:not([data-etat="en-cours"]) {
		button[data-action="annuler"] {
			display: none;
		}
	}

	&[data-etat="en-cours"] {

		button[data-action="noter"] {
			display: none;
		}
	}

	&[data-note] {
		[data-zone="ma-note"] {
			transition: height .3s ease-out;
			height: 100%;
		}
	}

}

body[data-noter="true"] {
	[data-zone="content-notes"] {
		[data-zone="notes"] {
			button[data-action="noter"] {
				display: none;
			}

			[data-zone="note"]:not([data-etat="en-cours"]) {
				opacity: 0.5;
			}

		}
	}
}

[data-zone="content-notes"] {
	margin-top: var(--marge-petite);

	@include headerArticle;

	hr {
		border-bottom: 8px solid var(--color-brand);
		width: calc(100% - var(--largeur-colonne) + var(--marge));
		margin-left: calc(var(--marge) * -1);

		@include petit {
			margin-left: calc(var(--marge-exterieure-grille) * -1);
			;
		}
	}
}


[data-zone="notes"] {


	&:first-of-type {
		margin-top: var(--marge-grande);
	}

	margin-bottom: var(--marge-grande);

	@include grid();

	>h2 {
		@include span(('all':6, 'mini':'content', 'grand':8));
		font-size: var(--taille-texte-smart);
		margin-bottom: var(--marge);

		@include petit {
			font-size: var(--taille-texte-grand-smart);
			margin: 0;
			margin-bottom: var(--marge-tres-grande);
		}

	}


	>h2 {}

	>div {
		@include span(('all':'content'));
		display: flex;
		flex-direction: column;
		@include gap(var(--marge-tres-grande));

		[data-zone="note"] {
			margin-top: 0;
			hr {
				order: 3;
				border-width: 1px;
			}
			&:last-of-type hr{
				display: none;
			}
			// &:not(:last-of-type):after {
			// 	content: '';
			// 	display: block;
			// 	border-bottom: 1px solid var(--color-brand);
			// 	width: calc(100% - var(--largeur-colonne) + var(--marge));
			// 	margin-left: calc(var(--marge) * -1);
			// 	margin-top: var(--marge);

			// 	@include petit {
			// 		margin-left: calc(var(--marge-exterieure-grille) * -1);
			// 		;
			// 	}
			// }
		}
	}
}