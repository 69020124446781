@import "base";

[data-select] {
    position: relative;

    [data-select-button] {
        position: relative;
        display: block;

        button {
            cursor: pointer;
            margin: 0;
            padding: 0;
            z-index: 1;
            position: absolute;
            inset: 0;
            opacity: 0;
            width: 100%;
        }
    }

    [data-select-template] {
        display: none;
    }

    [data-select-drawer] {
        display: none;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        padding: var(--marge);

        button {
            width: 100%;
            padding: var(--marge-mini);
            background-color: white;
            border: none;
            outline: 0;
            text-align: left;
            cursor: pointer;

            &[data-select-curseur="true"] {
                background-color: var(--color-mid);
                color: white;
            }

            &[data-selected="true"],
            &:hover {
                background-color: var(--color-brand);
                color: var(--color-text-alt);
            }
        }
    }

    &[data-drawer-is="open"] [data-select-drawer] {
        display: block;
    }

    [data-select-results] {
        @include paddingBlock(var(--marge));

        &:before {
            font-size: var(--taille-texte-petit);
            font-variation-settings: "wght"100;
            font-style: italic;
        }


        &:empty:before {
            content: 'Aucun résultat';
        }

    }

    &[data-select-loading="true"] [data-select-results] {
        &:before {
            animation: 3s chargement infinite;
            content: 'Chargement.';

        }
    }

}


@keyframes chargement {
    0% {
        content: 'Chargement.';
    }

    33% {
        content: 'Chargement..';
    }

    66% {
        content: 'Chargement...';
    }

}