@import "../inc/base";

[data-format="equipetype"] {
	[data-zone="article"]>header>[data-zone="photo"] {
		display: none;
	}
}
[data-zone="equipetype"] {
	[data-zone="photo"] {
		display: none
	}

	.terrain {
		@include moyen {
			margin-right: var(--marge);
		}

		>div {
			padding: 0;
			width: 100%;
			height: 0;
			position: relative;

			padding-bottom: 150.5%;
			margin-bottom: var(--marge);

			>svg {

				// border: 1px solid var(--color-brand);
				position: absolute;
				color: var(--color-brand);
				width: 100%;
				height: 100%;

				&[data-slug="terrain-h"] {
					display: none;
				}
			}


			@include moyen {
				padding-bottom: 66.5%;
				transform: initial;

				>svg[data-slug="terrain-h"] {
					display: block;
					border-right: 0;
				}

				>svg[data-slug="terrain-v"] {
					display: none;
				}
			}



			.compo {
				position: absolute;
				inset: 0;
				display: flex;
				@include gap(var(--marge));

				>div {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					@include gap(var(--marge));
					flex-direction: row-reverse;

				}

				@include moins-que-moyen {
					bottom: 10%;
					flex-direction: column;

					>div>div {
						display: flex;
						align-items: center;
						justify-content: center;
					}

				}

				@include moyen {
					right: 10%;

					>div {
						flex-direction: column;
					}
				}
			}
		}

		.joueur {
			display: block;
			background: var(--color-light);
			flex: 1;

			width: 14vmin;

			img {
				object-fit: cover;
				width: 100%;
				height: 21vmin;
			}
			span {
				display: none;
			}

			@include petit {
				img {
					height: 16vmin;
				}
	
				span {
					display: block;
				}
			}


			@include moyen {
				@include petite-grille {
					width: 7vmin;

					img {
						height: 10vmin;
					}
					span {
						display: none;
					}
				}

				@include grande-grille {
					width: 6vmax;

					img {
						height: 6.5vmax;
					}
				}
			}

			@include tres-grand {
				width: 12vmin;

				img {
					height: 13vmin;
				}
			}

			span {
				font-family: var(--police-sans-serif);
				font-weight: normal;
				font-variation-settings: "wght"600;
				color: var(--color-brand);
				padding: var(--marge-micro);
				line-height: 1em;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	.positions {
		.position {
			>div {
				display: flex;
				flex-direction: column;
				@include gap(var(--marge));
			}

			h2 {
				font-weight: normal;
				font-variation-settings: "wght"800;
				font-size: var(--taille-texte-tres-grand);
				margin: 0;
			}

			.joueur {

				// &:not(:first-child) {
				//     margin-bottom: var(--marge);
				// }

				// @include grille(5, 7, var(--marge));
				@include grid(('all':'content'));

				>figure {
					@include span(('all':1, 'grand':('start':2, 'span':1)));
					height: 0;
					padding-bottom: 100%;
					position: relative;
					border-radius: 50%;
					overflow: hidden;

					>img {
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: absolute;
						margin: 0;
					}
				}

				>div {
					@include span(('mini':3, 'petit':5, 'moyen':5, 'grand':('start':3, 'span':6)));

					h3 {
						font-weight: normal;
						font-variation-settings: "wght"600;
					}
				}
			}


			>div {

				@include petite-grille {
					grid-column: span 5;
				}

				@include grande-grille {
					grid-column: span 7;
				}
			}

			>hr {
				border-bottom: 8px solid var(--color-brand);
				width: calc(100% - var(--largeur-colonne) + var(--marge));
				margin-left: calc(var(--marge) * -1);

				@include petit {
					margin-left: calc(var(--marge-exterieure-grille) * -1);
					;
				}
			}
		}
	}
}

.terrain {
	&[data-compo="4-3-3"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(3) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(4) {
				>div {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge));
					}

					@include moyen {
						margin-left: calc(var(--marge));
					}
				}

			}
		}
	}
}

.terrain {
	&[data-compo="4-4-2"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(3) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

		}
	}
}


.terrain {
	&[data-compo="4-2-3-1"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(4) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

		}
	}
}

.terrain {
	&[data-compo="3-5-2"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(3) {
				@include gap(var(--marge-tres-grande));
			}

			>div:nth-child(4) {
				justify-content: space-around;

				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

		}
	}
}

.terrain {
	&[data-compo="5-3-2"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}


			>div:nth-child(3) {
				justify-content: space-between;

				>div {

					@include moins-que-moyen {
						@include marginInline(var(--marge));
						margin-top: var(--marge);
					}

					@include moyen {
						@include marginBlock(var(--marge));
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}
			}

			>div:nth-child(4) {
				justify-content: space-around;
			}

		}
	}
}


.terrain {
	&[data-compo="4-2-4"] {
		.compo {
			>div:nth-child(2) {
				>div {
					@include moins-que-moyen {
						margin-top: var(--marge);
					}

					@include moyen {
						margin-left: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-top: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-left: calc(var(--marge) * -1);
					}
				}

			}

			>div:nth-child(4) {
				>div {
					@include moins-que-moyen {
						margin-bottom: var(--marge);
					}

					@include moyen {
						margin-right: var(--marge);
					}
				}

				>div:nth-child(2),
				>div:nth-child(3) {
					@include moins-que-moyen {
						margin-bottom: calc(var(--marge) * -1);
					}

					@include moyen {
						margin-right: calc(var(--marge) * -1);
					}
				}

			}




		}
	}
}