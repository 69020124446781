@import "../inc/base";

[data-zone="slider"] {
    transition: height 1s ease-in-out;
    overflow: hidden;

    [data-zone="scroll"] {
        margin-bottom: -10vh;
        overflow-y: hidden;
        overflow-x: auto;
        scroll-behavior: smooth;
        box-sizing: content-box;
        scroll-snap-type: x mandatory;

        [data-zone="slides"] {
            display: flex;
            flex-flow: row;
            transition: transform 1s ease;
            // overflow: hidden;

            [data-zone="slide"] {
                margin-bottom: 10vh;
                padding-top: 2vh;
                position: relative;
                scroll-snap-align: start;
                flex: none;
                width: 100%;
                // min-height: calc(100% - vh);

                img {
                    height: 50vh;
                    object-fit: cover;
                }

                h2 {
                    margin-top: var(--marge-tres-petite);
                    margin-bottom: var(--marge-tres-petite);
                }

                // &:first-of-type>div {
                //     nav {
                //         >a[data-action="prev"] {
                //             display: none;
                //         }
                //     }
                // }

                // &:last-of-type>div {
                //     nav {
                //         >a[data-action="next"] {
                //             display: none;
                //         }
                //     }
                // }

                >div {
                    position: relative;

                    nav {
                        position: absolute;
                        width: 100%;
                        z-index: 2;
                        display: flex;
                        @include gap(var(--marge));

                        >span {
                            flex: 1;
                        }

                        >a {
                            margin: var(--marge-tres-petite);
                            flex: 0 0 32px;
                            height: 32px;
                            display: block;

                            svg {
                                filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
                                width: 100%;
                                height: 100%;
                                color: var(--color-light);

                                &:hover {
                                    color: var(--color-accent);
                                }
                            }

                            &:first-child {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}