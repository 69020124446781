@import "../inc/base";


.nav-article {
    display: none;
}

@include moins-que-moyen {

    body[data-loaded][data-scrolled]:not([data-scrolling]) {
        .nav-article {
            display: block;

            .liens a[class] {
                transform: translateX(0);
            }
        }
    }

    body[data-loaded]:not([data-scrolling]) {
        &[data-swipe] .nav-article {
            .apercus a {
                opacity: 1;
            }
        }

        &[data-swipe="left"] .nav-article {
            .apercus .suivant {
                transform: translateX(0);
            }
        }

        &[data-swipe="right"] .nav-article {
            .apercus .precedent {
                transform: translateX(0);
            }
        }
    }

    .nav-article {
        display: block;
        position: fixed;
        width: 100%;
        top: 8%;
        left: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        pointer-events: none;

        .apercus {
            width: 100%;
            position: absolute;
            top: 100%;
            display: flex;
            justify-content: space-between;

            >a {
                display: flex;
                flex: 1;
                text-decoration: none;
                transition: transform .3s ease;
                padding: var(--marge);
                background-color: var(--color-brand);
                pointer-events: all;
                flex-direction: column;
                @include gap(var(--marge-mini));

                >figure {
                    width: 100%;
                    margin: 0 !important;
                    aspect-ratio: 16/9;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .chemin-de-fer {
                    color: var(--color-brand-alt);

                    li {
                        font-size: var(--taille-texte-tout-petit);
                    }
                }

                >span {
                    // border: 2px solid var(--color-brand);
                    border-top: 0;
                    font-size: var(--taille-texte-moyen-grand);
                    color: var(--color-brand-alt);
                    font-family: var(--police-sans-serif);
                    font-weight: normal;
                    font-weight: 900;
                }

                position: relative;

                &:before {
                    display: block;
                    font-family: var(--police-sans-serif);
                    font-weight: normal;
                    font-weight: 900;
                    font-size: var(--taille-texte-petit);
                    background-color: var(--color-brand-alt);
                    position: absolute;
                    top: 0;
                    transform: translateY(-50%);
                    padding: .1em;
                }

                opacity: 0;

                &.precedent {
                    &:before {
                        right: 1em;
                        content: 'Article précédent';
                    }

                    transform: translateX(-150%);

                    span {
                        border-left: 0;
                    }
                }

                &.suivant {
                    &:before {
                        left: 1em;
                        content: 'À lire ensuite';
                    }

                    transform: translateX(150%);

                    span {
                        border-right: 0;
                    }
                }

            }
        }

        .liens {
            display: flex;

            [href=""] {
                opacity: 0;
                pointer-events: none;
            }

            justify-content: space-between;

            a:not([href=""]) {
                pointer-events: all;
                display: block;
                padding: var(--marge);
                scale: .6;
                transition: all .3s ease;

                &.precedent {
                    border-radius: 0 100% 100% 0;

                    >svg {
                        transform: rotate(180deg);
                    }

                    transform-origin: left center;
                    transform: translateX(-150%);
                }

                &.suivant {
                    border-radius: 100% 0 0 100%;
                    transform-origin: right center;
                    transform: translateX(150%);
                }

                width: 10vw;
                height: 10vw;
                background-color: rgba(var(--color-mid-rgb), 0.5);

                >svg {
                    opacity: 0.8;
                    width: 100%;
                    height: 100%;
                }

                >span {
                    position: absolute;
                    text-indent: -100vh;
                    overflow: hidden;

                }

                color: var(--color-light);

                &:hover {
                    scale: 1;
                    color: var(--color-brand);
                }
            }
        }
    }

}