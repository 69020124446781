blockquote {
    box-sizing: border-box;
    margin: var(--marge-grande) 0;
    padding: var(--marge);
    font-family: var(--police-sans-serif);
}

blockquote:first-child {
    margin-top: 0;
}

blockquote p {
    /* font-size: var(--taille-texte-grand); */
    font-weight: var(--font-weight-medium);
}

blockquote p:last-of-type,
blockquote p:only-of-type {
    margin-bottom: var(--marge-petite);
}

blockquote cite {
    display: block;
    font-family: var(--police-sans-serif);
    font-size: var(--taille-texte-petit);
    font-style: normal;
    font-weight: var(--font-weight-medium);
}

