@import "../inc/base";

.barre-article {
	// margin-bottom: var(--marge-petite);
	@include grid(('all':'content'));
	position: relative;

	&:before {
		position: absolute;
		content: '';
		height: 100%;
		width: var(--marge-exterieure-grille);
		background: var(--color-brand);
		left: calc(var(--marge-exterieure-grille) * -1);
	}

	// padding-left: var(--marge-exterieure-grille);

	>div {
		@include span(('mini':'content', 'petit':6, 'moyen':6, 'grand':8, 'grid':true));


		background: var(--color-brand);
		padding: var(--marge-mini) 0;


		>[data-zone] {
			// &:first-child {
			//     // margin-left: var(--marge-exterieure-grille);
			// }

			&:not(:last-child) {
				border-left: var(--border-size-3) solid var(--color-brand-alt);
			}

			padding-left: var(--marge-mini);

			&[data-zone="partages"] {
				@include span(('all':2, 'grand':3));
				flex: 1;
				display: flex;
			}

			&[data-zone="reactions"] {
				@include span(('mini':2, 'petit':3, 'moyen':3, 'grand':4));
				display: flex;
				@include gap(var(--marge));
				line-height: 28px;

				>a {
					svg {
						width: var(--icone);
						aspect-ratio: 1;
					}

					display: flex;
					transition: .5s ease;
					opacity: 0;

					&:hover {
						color: var(--color-accent);
					}

					// span {}
				}
			}

			span,
			a {
				color: var(--color-brand-alt);
				font-family: var(--police-sans-serif);
				font-weight: normal;
				font-weight: var(--font-weight-bold);
				text-decoration: none;
			}

			svg {
				vertical-align: bottom;
				fill: var(--color-brand-alt);
				margin-right: var(--marge-mini);
			}
		}

	}


	@include moins-que-petit {
		>div {
			display: flex;
			@include gap(var(--marge-mini));

			>[data-zone] {

				span,
				a {
					font-size: var(--taille-texte-tout-petit);
				}

				svg {
					height: var(--icone-petit);
				}

			}

			[data-zone="reactions"] {
				>a {
					display: none;
				}
			}

			[data-zone="partages"] {

				&[data-opened="true"] [data-action="share"] {
					display: none;
				}

				&:not([data-opened="true"])>a:not([data-action="share"]) {
					display: none;
				}

			}
		}
	}

	@include moins-que-grand {
		>div {
			[data-zone="reactions"] {

				>a>span {
					display: none;
				}
			}
		}
	}
	@include moins-que-moyen {
		>div {
			[data-zone="reactions"] {

				>a {
					display: none !important;
				}
			}
		}
	}

	@include petit {
		[data-zone] [data-action="share"] {
			display: none;

		}


		>div {
			// @include grilleSpan(6, 8);
			// @include grille(6, 8);

			>[data-zone] {
				svg {
					height: var(--icone);
					width: auto
				}
			}

			[data-zone="reactions"] {


				// @include grilleSpan(2, 4);

				>span {
					white-space: nowrap;
				}



				&:hover>a {
					opacity: 1;
				}

				// >a:first-of-type {
				// svg {
				//     // transform: rotate(90deg);
				// }
				// }

				>a:last-of-type {
					svg {
						transform: rotate(90deg);
						height: auto;
						width: var(--icone);
					}
				}
			}

			[data-zone="partages"] {

				// margin-left: var(--border-size-3);

				// @include grilleSpan(3, 3);

				>span:first-of-type {
					margin-right: var(--marge);
					line-height: 28px;
				}
			}

			[data-zone="likes"] {
				position: relative;
				grid-column: span 1;
				border: none;
				display: flex;
				@include gap(var((--marge-mini)));

				>span {
					flex: 1;
					text-align: right;
					line-height: 28px;
				}
			}

		}

		// margin-bottom: var(--marge);
	}

}