@import "../inc/base";

[data-zone="lexique"] {
    @include grid(('all':'content'));
    margin-bottom: var(--marge-tres-tres-grande);
    @include petit {
        display: flex;
        flex-direction: column;
        @include gap();
    }

    >p {
        font-weight: normal;
        font-weight: var(--font-weight-bold);
    }
}

[data-zone="navigation-lexique"] {
    font-family: var(--police-sans-serif);
    font-weight: normal;
    position: relative;
    display: flex;
    height: 100vh;

    >button[data-action="ouvrir-fermer-menu-lexique"] {
        background-color: inherit;
        position: absolute;
        left: 100%;
        bottom: 10%;
        transform-origin: top left;
        transform: rotate(-90deg);
        color: var(--color-brand-alt);
        font-family: inherit;
        font-weight: inherit;
        font-size: var(--taille-texte-grand);
        font-weight: var(--font-weight-bold);

        padding: var(--marge-micro) var(--marge-mini);

        cursor: pointer;
        display: flex;

        span {
            flex: 1;
        }

        svg {
            width: var(--icone);
            height: var(--icone-moyen);
        }
        @include petit {
            display: none;
        }
    }



    // margin-left: calc(var(--marge-exterieure-grille) * -1);
    // padding-left: var(--marge-exterieure-grille);
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    transition: transform .5s ease;
    width: var(--largeur-colonne);
    border-left: var(--marge-exterieure-grille) solid var(--color-brand);
    // z-index: 99999;

    @include petit {
        width: auto;
        position: relative;
        flex-direction: row;
        padding: 0;
        padding-right: var(--marge);
        margin: 0;
        height: auto;
        justify-content: space-between;
        // @include paddingBlock(var(--marge-mini));
    }

    background: var(--color-brand);


    >a {

        text-align: center;
        color: var(--color-brand-alt);

        &:not(:first-of-type) {
            border: none;
            border-top: 1px solid var(--color-brand-alt);
        }

        margin: 0 var(--marge);
        @include paddingBlock(var(--marge-micro));

        @include petit {
            padding: 0;
            margin: var(--marge-tres-petite) 0;

            &:not(:first-of-type) {
                border: none;
                border-left: 1px solid var(--color-brand-alt);
            }

            font-size: var(--taille-texte-petit-smart);
            flex: 1 1 auto;
            line-height: 80%;
        }


        &:hover,
        &[data-selected="true"],
        &:focus,
        &:active {
            font-weight: 900;
        }

        &:hover {
            color: var(--color-accent);
            text-decoration: none;
        }
    }
}

[data-zone="mots"] {

    >div {
        // &:nth-child(odd) {
        //     @include span(('grand':4, 'all':3,'petit':'content','grid':true));
        // }
        // &:nth-child(even) {
        //     @include span(('grand':4, 'all':('start':5,),'petit':'content','grid':true));
        // }

        >* {
            font-family: var(--police-sans-serif);
            font-weight: normal;
            font-weight: 900;
        }

        >div {
            font-size: var(--taille-texte-tres-tres-grand-smart);
            margin-top: calc(var(--marge-petite) * -1);
        }

        >ul {
            // @include span(('grand':3, 'all':2, 'mini':2));

            a {
                font-size: var(--taille-texte-tres-grand);

                @include petit {
                    font-size: var(--taille-texte-grand);
                }

                color:inherit;
            }
        }
    }
}



[data-zone="mots"] {
    @include span(('all':'content', 'row-gap':calc(var(--marge-tres-tres-grande) * 2), 'grid':true));

    >div {
        @include span(('grand':4, 'all':3, 'grid':true));

        >ul {
            @include span(('grand':3, 'all':2, 'mini':2));
        }
    }
}


[data-zone="mot"] {
    @include span(('all':'content', 'grid':true));

    >div {
        @include span(('grand':('start':2, 'span':7), 'all':('start':2, 'span':5), 'mini':'content'));

        >div {
            @include grid(('grand':7, 'row-gap':var(--marge)));


            >* {
                @include span(('grand':6));

            }
        }

        display: flex;
        flex-direction: column;
        @include gap();

        p,
        h2 {
            margin: 0;
        }

        >nav {
            display: flex;

            >*:first-child {
                flex: 1;
            }
        }
    }
}

@include moins-que-moyen {
    [data-zone="lexique"] {
        &[data-menu="ouvert"] {

            [data-zone="mots"] {
                @include span(('mini':('start':2, 'span':3)));

            }
        }

        &:not([data-menu="ouvert"]) {


            [data-zone="navigation-lexique"] {
                transform: translateX(-100%);

                >button[data-action="ouvrir-fermer-menu-lexique"] svg {
                    display: none;
                }
            }

        }
    }
}