/** 
    Documentation des grilles : https://docs.google.com/document/d/1vcFAeDToCcRAIJdtjY_e7bLdtVLunqKuxyUHGuCg_ro/edit?usp=sharing
    Exemples à voir dans live/_equipes.scss
    Définir une grille : Quel nombre de colonnes, quel gap. Ceci permet d'aligner des élements à la grille du design. 
    On ne peut pas définir de grille 2D avec cet outil (colonnes plutot que vraie grille)
    
    Un appel unique permet de définir le nombre de colonne dans chacun des niveaux des grilles.
    Exemples d'appel :

        @include grid(('mode':'content')); (c'est le comportement par défaut)
        Définit la grille par defaut pour la colonne de contenu du site. 5 colonnes en mini, 7 en petite, 7 en moyenne et 9 en grande

        @include grid(('mode':'max'));
        Définit la grille par defaut pour tout le site. 5 colonnes en mini, 7 en petite, 7 en moyenne et 12 en grande

    Il est possible de changer le nombre de colonnes par défaut pour un niveau de grille, mais ce n'est pas recommandé

    L'appel au mixin grid est à compléter avec le mixin span ci-dessous
*/
@mixin grid($params: ()) {
	display: grid;

	$mode: param($params, "mode");
	$all: param($params, "all");

	$mini: 'content';
	$petit: 'content';
	$moyen: 'content';
	$grand: 'content';

	@if notEmpty($all) {
		$mini: $all;
		$petit: $all;
		$moyen: $all;
		$grand: $all;
	}

	$mini: param($params, "mini", $mini);
	$petit: param($params, "petit", $petit);
	$moyen: param($params, "moyen", $moyen);
	$grand: param($params, "grand", $grand);

	@if notEmpty($mode) {
		@if $mode=='content' {
			$mini: 'content';
			$petit: 'content';
			$moyen: 'content';
			$grand: 'content';
		}

		@if $mode=='max' {
			$mini: 'max';
			$petit: 'max';
			$moyen: 'max';
			$grand: 'max';
		}
	}

	$gap: param($params, "gap");
	$rowGap: param($params, "row-gap");


	@if $mini=='max' or $mini=='content' {
		$mini: 4;
	}


	@if $petit=='content' {
		$petit: 7;
	}

	@if $petit=='max' {
		$petit: 7;
	}


	@if $moyen=='content' {
		$moyen: 7;
	}

	@if $moyen=='max' {
		$moyen: 10;
	}


	@if $grand=='content' {
		$grand: 9;
	}

	@if $grand=='max' {
		$grand: 12;
	}

	@include gridGap($gap, $rowGap);

	@if $mini {
		content:'mini : #{$mini}';

		@include grid-mini {
			grid-template-columns: repeat($mini, 1fr);
		}
	}

	@if $petit {
		content:'petit : #{$petit}';

		@include grid-petite {
			grid-template-columns: repeat($petit, 1fr);
		}
	}

	@if $moyen {
		content:'moyen : #{$moyen}';

		@include grid-moyenne {
			grid-template-columns: repeat($moyen, 1fr);
		}
	}

	@if $grand {
		content:'grand : #{$grand}';

		@include grid-grande {
			grid-template-columns: repeat($grand, 1fr);
		}
	}
}

/**
    Une fois que la grille est mise en place avec le mixin grid, on peut définir l'étendu de chacun des elements de la grille définie avec span
    Exemples d'utilisations :
    
    @include span(('all':1)); 
    Tous les fils de la grille s'étendrons sur une colonne à chaque niveau de la grille 
    (c'est le comportement par defaut si on ne fait pas appel à span)

    @include span(('all':2)); 
    Tous les fils de la grille s'étendrons sur 2 colonnes à chaque niveau de la grille 

    @include span(('mini':2,'petit':1,'moyen':1,'grand':3)); 
    Tous les fils de la grille s'étendrons sur 
        - 2 colonnes dans la grille 'mini'
        - 1 colonne dans la grille 'petit'
        - 1 colonne dans la grille 'moyen'
        - 3 colonne dans la grille 'grand'


    @include span(('all':2,'mini':1)); 
    Tous les fils de la grille s'étendrons sur 2 colonnes à chaque niveau de la grille sauf dans la grille mini

    @include span((
            'mini':('span':1, 'start':2),
            'petit':('span':1, 'start':3),
            'moyen':('span':3, 'start':3),
            'grand':('span':2, 'start':4),
     ));
    Tous les fils de la grille s'étendrons sur 
        - 1 colonne dans la grille 'mini' à partir de la 2e colonne disponible
        - 1 colonne dans la grille 'petit' à partir de la 3e colonne disponible
        - 3 colonnes dans la grille 'moyen' à partir de la 3e colonne disponible
        - 2 colonnes dans la grille 'grand' à partir de la 4e colonne disponible

    @include span((
        'mini':('span':1, 'start':2),
        'all':1,
    ));
    Tous les fils de la grille s'étendrons sur 1 colonne à chaque niveau de la grille sauf dans la grille mini, ou l'étendue sera d'une 1 colonne à partir de la 2e colonne

    @include span((
            'grid':true, // cet element de grille sera lui meme une grille, ayant un nb de colonnes egal aux spans qui lui sont passé en parametres ci-dessous
            'mini':('span':1, 'start':2),
            'petit':('span':1, 'start':3),
            'moyen':('span':3, 'start':3),
            'grand':('span':2, 'start':4),
     ));

*/
@mixin span($params: ()) {

	$mini: param($params, "mini", 'content');
	$petit: param($params, "petit", 'content');
	$moyen: param($params, "moyen", 'content');
	$grand: param($params, "grand", 'content');

	$all: param($params, "all");
	$grid: param($params, "grid");
	$gap: param($params, "gap");
	$rowGap: param($params, "row-gap");

	@if notEmpty($all) {
		$mini: $all;
		$petit: $all;
		$moyen: $all;
		$grand: $all;
	}

	@if param($params, "mini") {
		$mini: param($params, "mini");
	}

	@if param($params, "petit") {
		$petit: param($params, "petit");
	}

	@if param($params, "moyen") {
		$moyen: param($params, "moyen");
	}

	@if param($params, "grand") {
		$grand: param($params, "grand");
	}

	/*    $mode: param($params, "mode");

    @if notEmpty($mode) {
        @if $mode=='content' {
            $mini: 'content';
            $petit: 'content';
            $moyen: 'content';
            $grand: 'content';
        }

        @if $mode=='max' {
            $mini: 'max';
            $petit: 'max';
            $moyen: 'max';
            $grand: 'max';
        }
    }*/

	$spanMini: $mini;
	$startMini: false;
	$spanPetit: $petit;
	$startPetit: false;
	$spanMoyen: $moyen;
	$startMoyen: false;
	$spanGrand: $grand;
	$startGrand: false;

	@if param($mini, "span") {
		$spanMini: param($mini, "span");
		$startMini: param($mini, "start");
	}

	@if param($petit, "span") {
		$spanPetit: param($petit, "span");
		$startPetit: param($petit, "start");
	}

	@if param($moyen, "span") {
		$spanMoyen: param($moyen, "span");
		$startMoyen: param($moyen, "start");
	}

	@if param($grand, "span") {
		$spanGrand: param($grand, "span");
		$startGrand: param($grand, "start");
	}

	@if $spanMini=='max' or $spanMini=='content' {
		$spanMini: 4;
	}

	@if $spanPetit=='content' {
		$spanPetit: 7;
	}

	@if $spanPetit=='max' {
		$spanPetit: 7;
	}

	@if $spanMoyen=='content' {
		$spanMoyen: 7;
	}

	@if $spanMoyen=='max' {
		$spanMoyen: 10;
	}

	@if $spanGrand=='content' {
		$spanGrand: 9;
	}

	@if $spanGrand=='max' {
		$spanGrand: 12;
	}

	@if $spanMini {
		@include grid-mini {
			@if notEmpty($startMini) {
				grid-column: $startMini / span $spanMini;
			}

			@else {
				grid-column: span $spanMini;
			}
		}
	}


	@if $spanPetit {
		@include grid-petite {
			@if notEmpty($startPetit) {
				grid-column: $startPetit / span $spanPetit;
			}

			@else {
				grid-column: span $spanPetit;
			}
		}
	}

	@if $spanMoyen {
		@include grid-moyenne {
			@if notEmpty($startMoyen) {
				grid-column: $startMoyen / span $spanMoyen;
			}

			@else {
				grid-column: span $spanMoyen;
			}
		}
	}

	@if $spanGrand {
		@include grid-grande {
			@if notEmpty($startGrand) {
				grid-column: $startGrand / span $spanGrand;
			}

			@else {
				grid-column: span $spanGrand;
			}
		}
	}


	@if notEmpty($grid) {
		@include grid(('mini':$spanMini,
				'petit':$spanPetit,
				'moyen':$spanMoyen,
				'grand':$spanGrand,
				'gap':$gap,
				'row-gap':$rowGap))
	}
}




@mixin grid-grande {
	$this: &;

	@at-root {
		@include tres-grand {

			html:not([data-habillage="true"]):not([data-arche="true"]) {
				#{$this} {
					content: 'grid-grande #{$this}';
					@content;
				}

			}
		}
	}
}

@mixin grid-petite {
	$this: &;

	@include petit {
		// @include moins-que-tres-grand {
		content: 'grid-petite';
		@content;
		// }
	}

}


@mixin grid-moyenne {
	$this: &;

	@include grand {
		// @include moins-que-tres-grand {
		content: 'grid-moyenne';
		@content;
		// }
	}

	// @at-root {

	//     html[data-habillage="true"],
	//     html:has(body.body-habillage),
	//     html[data-arche="true"] {
	//         #{$this} {
	//             @content;
	//         }
	//     }
	// }
}

@mixin grid-mini {

	// @include moins-que-petit {
	content: 'grid-mini';
	@content;
	// }

}