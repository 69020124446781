@import "../inc/base";



[data-zone="likes"] {
    .jaime {
        @include moins-que-moyen {
            position: fixed;
            left: var(--marge-exterieure-grille);
            right: var(--marge-exterieure-grille);
            top: 30vh;
            bottom: 10vh;

            h3> {

                span,
                button {
                    font-size: var(--taille-texte-grand);
                }
            }

            &:after {
                display: none;
            }
        }

        @include moyen {
            position: absolute;
            width: 100vw;
            max-width: 400px;
            transform: translateX(-50%);

        }

        z-index: 1500;
        background-color: var(--color-brand-alt);
        top:calc(100% + var(--marge));

        h3,
        &:after {
            margin: 0;
        }

        box-shadow: -6px -1px 26px 14px rgba(0, 0, 0, 0.36);

        >div {
            margin: 0 var(--marge);
        }
    }
}

[data-action="jaimes"] {
    cursor: pointer;
}

.jaime {
    &:not([data-loaded])>* {
        display: none !important;
    }

    &,
    >div {
        display: flex;
        flex-direction: column;
        @include gap();

    }
}

.jaime {
    @include moyen {
        max-width: calc(100% - var(--largeur-colonne) - var(--marge));
    }

    &:has(.jaime__avis:empty) h4 {
        display: none;
    }

    h3,
    h4 {
        margin: 0;
    }

    h3 {
        display: flex;

        span {
            flex: 1;
        }

        button {
            color: var(--color-brand-alt);
        }
    }

    h3,
    &:after {
        background-color: var(--color-brand);
        color: var(--color-brand-alt);
        padding: var(--marge-mini);
        margin-left: calc(-1 * var(--marge-exterieure-grille));
    }

    &:after {
        display: block;
        content: '';
        border-bottom: 3px solid var(--color-brand);
    }

    >div {
        max-height: 100%;
        overflow: hidden;
    }

    &[data-closed] {
        [data-action="fermer"] {
            display: none;
        }

        .jaime__choix,
        h4 {
            display: none;
        }

        .jaime__avis {
            cursor: pointer;
        }
    }

    &:not([data-closed]) {
        [data-action="ouvrir"] {
            display: none;
        }
    }

}

.jaime__choix {

    display: flex;
    @include gap(var(--marge-mini));
    flex-wrap: wrap;

    li {
        flex: 0 1 auto;
        display: grid;
        place-items: center;

        input[type="emoji"] {
            outline: 0;
            display: block;
            width: var(--icone-grand);
            aspect-ratio: 1;
            border: 0;
            border-bottom: 2px var(--color-brand) solid;
            position: relative;
            text-align: center;
        }
    }

    &:not([data-autre]) .jaime__saisie {
        display: none;
    }

    &[data-autre] .jaime__autre {
        display: none;
    }

    button {
        font-size: var(--taille-texte-tres-grand-smart);

        @include moyen {
            font-size: var(--taille-texte-grand);
        }

        transition: transform 0.8s ease;
        transform-origin: center;

        &[data-action="jaime"]:hover {
            transition: transform 0.2s ease;
            transform: scale(1.5);
        }
    }


}

.jaime__avis {
    display: flex;
    @include gap(var(--marge-mini));
    flex-wrap: wrap;

    li {
        display: flex;
        @include gap(var(--marge-micro));

        >abbr[title] {
            border: none;
            text-decoration: none;
        }

        &,
        >abbr {
            // font-size: var(--taille-texte-grand);
        }

        >abbr {
            // width: var(--icone-petit);
            // aspect-ratio: 1;
            cursor: pointer;
            display: flex;

            >span {
                transition: transform 0.8s ease;

                &:hover {
                    transition: transform 0.2s ease;
                    cursor: pointer;
                    z-index: 99999;
                    transform: scale(1.2);
                }

                position: relative;
                text-shadow: 0 0 0px white,
                -2px -2px 0px white,
                -2px 2px 0px white,
                2px 2px 0px white,
                2px -2px 0px white;
            }

            >span:not(:first-child) {
                margin-left: -.9em;
            }
        }

        >span {
            display: grid;
            place-items: center;

            >span {
                background-color: var(--color-mid);
                color: black;
                border-radius: 16px;
                font-family: var(--police-sans-serif);
                font-size: var(--taille-texte-micro);
                padding: var(--marge-micro) var(--marge-mini);
            }
        }
    }
}