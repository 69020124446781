.notes-clavier {
    transform: translateY(150%);
    transition: transform .5s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include moyen {
        left: var(--marge-exterieure-grille);
        right: var(--marge-exterieure-grille);
    }

    z-index: 10001;

    @include grid(('all':'max'));

    >div {
        // border: var(--border-size-4) solid var(--color-brand-alt);
        border-bottom: 0;
        position: relative;
        // box-shadow: -6px -1px 26px 14px rgba(0, 0, 0, 0.36);

        h3 {
            margin: 0;
            margin-right: var(--icone);

            &,
            span {
                color: var(--color-brand-alt);
            }
        }

        padding:var(--marge);
        background-color: var(--color-brand);

        @include span(('mini':'content', 'all':('start':2, 'span':5)));

        [data-action="fermer"] {
            border-radius: 50%;
            color: var(--color-brand-alt);
            position: absolute;
            right: calc(var(--icone) / 2);
            top: calc(var(--icone) / 2);
            width: var(--icone);
            height: var(--icone);

            svg {
                fill: var(--color-brand-alt);
                width: 100%;
                height: 100%;
            }
        }

        >.boutons {
            display: grid;
            margin-top: var(--marge-mini);
            grid-template-columns: repeat(4, minmax(var(--icone-grand), 1fr));

            @include moyen {
                grid-template-columns: repeat(6, minmax(var(--icone-grand), 1fr));
            }

            @include gap();

            >* {
                display: block;
                aspect-ratio: 1;
                margin: 0;
                padding: 0;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }

            button {

                background-color: var(--color-brand-alt);
                color: var(--color-brand);
                font-size: 5vw;
                font-weight: bold;

                @include grand {
                    font-size: 2.5vw;
                }

                // width: var(--icone-grand);
                // height: var(--icone-grand);

                &:focus,
                &:hover {
                    background-color: var(--color-accent);
                    color: var(--color-dark)
                }
            }
        }

        >[data-zone="aide"] {
            margin-top: var(--marge);
        }


        button[data-action="afficher-masquer"] {
            color: var(--color-brand-alt);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .aide {
            color: var(--color-brand-alt);

            ul {
                display: flex;

                >li {
                    flex: 1;
                    text-align: center;
                }
            }
        }
    }
}

[data-noter="true"] {
    .notes-clavier {
        transform: translate(0);
    }
}