@import "../inc/base";

a[data-meta] {

    position: relative;

    >span {
        display: none;

        @include moyen {
            z-index: 10000;
            width: calc(var(--largeur-colonne) * 2 + var(--marge));

            transition: opacity .5s ease;
            opacity: 1;
            display: flex;
            flex-direction: column;


            position: absolute;
            top: 0;
            box-sizing: content-box;
            left: 0;
            // transform: translateX(-50%);
            border-top: 1.5em transparent solid;

            &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: var(--marge) solid transparent;
                border-right: var(--marge) solid transparent;

                border-bottom: var(--marge) solid var(--color-brand);
                ;
            }

            >span {
                >* {
                    margin: 0;
                }

                position: relative;

                button[data-action="fermer"] {
                    background-color: var(--color-brand-alt);
                    color: var(--color-brand);
                    width: var(--icone);
                    height: var(--icone);
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translate(50%, -50%);

                    &:hover {
                        background-color: var(--color-accent);
                        color: var(--color-light);

                    }
                }

                display: flex;
                flex-direction: column;
                gap: var(--marge-mini);
                border-right: 1px solid var(--color-brand-alt);
                border-bottom: 1px solid var(--color-brand-alt);
                background-color: var(--color-brand);
                color: var(--color-brand-alt);
                padding: var(--marge);

                h3 {
                    font-size: var(--taille-texte);
                }

                >figure {
                    aspect-ratio: 4/3;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

a[data-meta] {
    &:not(:hover)>span {
        visibility: hidden;
        transform: scale(0);
        opacity: 0;
    }
    &[data-closed] > span{
        display: none;
    }
}