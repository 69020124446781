@import "../inc/base";

figure[data-mode="citation"] {
  @include marginBlock(var(--marge));
  position: relative;
  // @include span(('all':'content', 'grid':true, 'row-gap':var(--marge-tres-petite)));

  &:before {
    position: absolute;
    content: "";
    background: var(--color-brand);
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.4 52.4"><path d="M17.6 0L0 26.1l17.6 26.3h14.2L15.9 26.2 31.8 0z"/><path d="M44.2 0L26.6 26.1l17.6 26.3h14.2L42.5 26.2 58.4 0z"/></svg>')
      no-repeat right top;
    mask-size: 100%;
    width: var(--largeur-colonne);
    height: 100%;
    top: 0;
    left: 0;
  }

  > blockquote {
    &,
    & > p {
      margin: 0;
      padding: 0;
      font-family: var(--police-sans-serif);
      font-size: var(--taille-texte-tres-grand);
      font-weight: var(--font-weight-bolder);
      font-weight: 900;
      line-height: 110%;
      color: var(--color-brand);
    }

    > p:after {
      content: "»";
      display: inline;
	  margin-left: var(--marge-mini);
    }
  }

  > figcaption {
    font-size: var(--taille-texte-petit-smart);
  }

  > blockquote,
  > figcaption {
    padding-left: calc(var(--largeur-colonne) + var(--marge));
  }

  @include moyen {
    &:before {
      mask-size: 60%;
    }
  }
}
