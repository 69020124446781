@import "../inc/base";

.side .fiches {
	@include moins-que-petit {
		display: none;
	}
	>ul {
		li {
			a {
				color: var(--color-brand-alt)
			}
		}
	}

	>div {
		background-color: var(--color-brand);
		color: var(--color-brand-alt);
		padding: var(--marge);

		>h2 {
			font-weight: var(--font-weight-medium);
			font-family: var(--police-serif);
			font-size: var(--taille-texte-moyen);
			letter-spacing: -0.53px;
		}

		>ul {
			line-height: 1.35em;

			li {
				margin-bottom: var(--marge-micro);

				a {
					font-variation-settings: 'wght'var(--font-weight-bold);
					font-family: var(--police-sans-serif);
					color: var(--color-brand-alt);
					font-size: var(--taille-texte-moyen-grand)
				}
			}
		}
	}
}