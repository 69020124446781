[data-interview="true"] article[data-zone="article"] {

    .content {
        display: flex;
        flex-direction: column;
        // @include gap(var(--marge-mini));
        gap: var(--marge-mini) !important;

        >p {
            margin: 0 !important;
        }

        div.question,
        p.question {
            font-size: var(--taille-texte-moyen-grand);
            font-weight: normal;
            font-weight: var(--font-weight-bold);
            margin-top: var(--marge);
            margin-bottom: 0;
        }

        strong.question {
            font-size: calc(var(--taille-texte-grand)*0.9);
            display: block;
            margin-top: var(--marge);
            margin-bottom: 0;
        }
    }
}