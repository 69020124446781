@import "../inc/base";

figure[data-mode="exergue"] {
    @include marginBlock(var(--marge));
    display: flex;

    &:before {
        display: block;
        content: '';
        border-right: var(--border-size-4) solid var(--color-brand);
        flex: 0 0 calc(var(--largeur-colonne) - var(--border-size-4));
    }


    >blockquote {
        margin: 0;
        flex: 1;

        &,
        &>p {
            padding: 0;
            padding-left: var(--marge);
            font-family: var(--police-sans-serif);
            font-size: var(--taille-texte-smart);
            font-weight: var(--font-weight-bolder);
            font-weight: 900;
            line-height: 110%;
        }
    }
}