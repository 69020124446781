table {
    width: 100%;
    border-collapse: collapse;
    font-family: var(--police-sans-serif);
    margin-bottom: var(--marge-grande);
}

caption {
    font-size: var(--taille-texte-tres-grand);
    text-align: left;
}

tbody.tableau-formate tr {
    &:nth-child(odd) {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-brand);
            opacity: .1;
						z-index: -1;
        }
    }
    td a {
        color: inherit;
        text-decoration: underline;
        &:hover {
            text-decoration: none;    
        }
    }
}

th,
td {
    padding: 10px 0;
		font-size: var(--taille-texte);
		font-family: var(--police-sans-serif);
    text-align: left;
    vertical-align: middle;
}

th {
	font-weight: var(--font-weight-bold);
}

th h3 {
    margin: 0;
    font-size: var(--taille-texte-petit);
    text-transform: none;
}

td:first-of-type {
    padding-left: var(--marge-micro);
}

td {
    font-weight: 900;
}

.td--divider {
    padding-left: var(--marge-petite);
    border-left: var(--border-size-1) solid var(--color-border);
}


/* condensed */

.table--condensed th,
.table--condensed td {
    padding-top: var(--marge-mini);
    padding-bottom: var(--marge-mini);
}


/* mini */

.table--mini th,
.table--mini td {
    padding-top: var(--marge-micro);
    padding-bottom: var(--marge-micro);
    font-size: var(--taille-texte-tout-petit);
}


/* stripe */

.table--stripe tbody th,
.table--stripe tbody td {
    border-bottom-width: 0;
}

.table--stripe tbody tr:nth-of-type(even) th,
.table--stripe tbody tr:nth-of-type(even) td {
    background-color: var(--color-base);
}

.table--stripe tbody tr:last-of-type th,
.table--stripe tbody tr:last-of-type td {
    border-bottom: var(--border-size-1) solid var(--color-border);
}


/* hover */

.table--hover tr:hover th,
.table--hover tr:hover td,
.table--hover tbody tr:nth-of-type(even):hover th,
.table--hover tbody tr:nth-of-type(even):hover td {
    background-color: var(--color-border);
}


[data-page="page"] {
	th {
		font-weight: 900;
	}

	td {
		font-weight: var(--font-weight-bold);
	}
}

/* responsive */

@media (max-width:37.5em) {
    .table--responsive {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        overflow-x: auto;
        border-spacing: 0;
        -webkit-overflow-scrolling: touch;
    }
}