.produit {
    margin-inline: var(--marge-grande);

    @include grand {
        margin-inline: 0;
    }

    >a {
        display: flex;
        flex-direction: column;

        @include grand {
            flex-direction: row;
        }

        gap: var(--marge);
        border-radius: 15px;
        overflow: hidden;
        align-items: center;
        box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.15);
        align-items: stretch;

        @include grand {
            margin-inline: auto;
            max-width: 1000px;
        }

        >figure {
            margin: 0;

            img {
                display: block;
                max-height: 45vh;
                min-height: 15vh;
                height: 100%;
                object-fit: cover;

                @include moyen {
                    height: 10vh;
                }

                margin: 0;
            }
        }

        >header {
            display: flex;
            flex: 1;

            >span {
                font-family: sans-serif;
                font-size:
                    var(--taille-texte-tout-petit);
                text-transform: uppercase;
            }

            padding: var(--marge);
            @include grand {
                padding: var(--marge-mini);
            }
            display: flex;
            gap: var(--marge-mini);
            flex-direction: column;
            justify-content: center;

            >* {
                margin: 0;
            }

            u {
                color: var(--color-brand);
            }

            p {
                font-size: var(--taille-texte-tout-petit);

                @include moyen {
                    font-size: var(--taille-texte-petit);

                }
            }
        }

        >footer {
            padding: var(--marge);
            background-color: var(--color-brand);
            color: var(--color-brand-alt);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--marge-mini);
            flex-direction: column;

            >strong {
                font-family: var(--police-sans-serif);
                font-size: var(--taille-texte-tres-grand);
            }

        }
    }
}