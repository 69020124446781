@import "../inc/base";

// [data-habillage="true"] {
//     .barre-articles {
//         margin-right: var(--marge);
//     }
// }
[data-arche="true"]:has([data-zone="article"]) {
     .barre-articles {
        div {
            article:nth-child(n+4) {
                display: none;
            }
        }
    }
}

.barre-articles {
    display: flex;
    @include gap(var(--marge-mini));
    flex-direction: column;

    >div {
        &:first-of-type {
            margin: 0;
            font-size: var(--taille-texte-petit-smart);
            font-family: var(--police-sans-serif);
            font-weight: normal;
            font-weight: 900;
            line-height: var(--hauteur-ligne-petite);
        }

        &:last-of-type {
            @include grid(('mini':'content'));

            >* {

                @include span(('all':2, 'petit':3));
            }

            >*:nth-child(n+5) {
                display: none !important;
            }

            // >div:not(.article) {
            //     &:before {
            //         width: 100%;
            //         aspect-ratio: 16/9;
            //         display: block;
            //         content: '';
            //         background-color: var(--color-mid);
            //         opacity: 0.5;
            //     }
            // }

            .article {

                &:not(:has(figure[data-zone="photo"])) {
                    &:before {
                        content: '';
                        display: block;
                        aspect-ratio: 16/9;
                        background: var(--color-brand) no-repeat;
                        background-size: cover;
                        background-image: url(/img/sofoot-blue.jpg);
                        outline: 1px solid var(--color-brand);

                    }
                }

                .chemin-de-fer {
                    display: none;
                }

                p {
                    display: none;
                }

                h2.titre {
                    // &[data-mode="long"] {
                    //     display: none;
                    // }

                    // &[data-mode="court"] {
                    //     display: block;
                    // }
                }

                [data-zone="photo"] img {
                    aspect-ratio: 16/9;
                }

                .article-actions {
                    display: none
                }

                >div header>.titre {
                    font-size: var(--taille-texte);
                }
            }
        }
    }
}