@import "../inc/base";



[data-zone="commentaires"] {

    // @include grid(('mini':'content'));
    display: flex !important;
    justify-content: center !important;
    max-width: 900px;

    width: 100%;
    @include moyen {
        padding-right: var(--marge);
        width: calc(100% - var(--marge));
    }

    margin: 0 auto;

    >div {
        width: 100%;

        >div {
            width: 100%;

            &:first-child {
                display: none;
            }
        }
    }
}

body:not([data-commentaires-ready="true"]) {
    @include moyen {
        [data-zone="commentaires"] {
            // @include grid(('mini':'content'));

            >div {

                // @include span(('mini':'content', 'all':('start':2, 'span':5)));
            }
        }
    }
}

/*
@include moins-que-moyen {
    [data-zone="commentaires"] {
        // @include grid(('mini':'content'));

        >div {

            @include span(('mini':'content', 'all':('start':2, 'span':5)));
        }
    }
}
*/

@include moyen {
    body[data-commentaires-ready="true"] {
        [data-zone="commentaires"] {
            transform: translateX(100%);
            --gaps: calc(11 * var(--marge));
            --ext: calc(2 * var(--marge-exterieure-grille));
            --largeur-site: calc(100% - var(--gaps) - var(--ext));
            --largeur-colonne: calc(var(--largeur-site) / 12);
            --colonnes: 4;
            z-index: 9998;
            position: fixed;
            right: 0;
            top: 0;
            height: 100%;
            //            width: calc((var(--largeur-colonne) * var(--colonnes)) + (calc(var(--colonnes) - 1) * var(--marge)));
            //            padding-right: var(--marge-exterieure-grille);
            width: calc(100% / 3);
            // @include grid(('all':var(--colonnes)));
            background-color: var(--color-light);
            display: flex;
            padding-right: var(--marge);

            >div {
                flex: 1;
                display: flex;
                margin: 0;
                padding: 0;
                border: 0;
                @include gap(calc(var(--marge) / 2));

                >div {
                    &:first-child {
                        display: block;
                        flex: 0 0 var(--marge);
                        background-color: var(--color-brand);
                        border-left: var(--border-size-2) solid var(--color-light);

                        svg {
                            color: var(--color-light);
                        }
                    }

                    &:last-child {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        >div {
                            padding-left: calc(var(--marge) / 2);
                            direction: rtl;
                            overflow-y: auto;
                            overscroll-behavior-y: contain;
                            flex: 1;
                            scrollbar-color: var(--color-accent) var(--color-light);
                            scrollbar-width: thin;
                            transition: all .5s;

                            >* {
                                direction: ltr;
                            }

                            // @include span(('all':var(--colonnes)));

                        }
                    }

                }
            }
        }

    }

    body[data-commentaires-start="true"] {
        [data-zone="commentaires"] {
            transition: transform .5s;
        }
    }

    body[data-commentaires-open="true"] {
        [data-zone="commentaires"] {
            transform: translateX(0);
        }
    }
}